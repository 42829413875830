const H1 = (props) => {
    const text = props.children
    const styles = props.styles
    
    return (
        <div className={styles.h1}>
            <div></div>
            <h1>{text}</h1>
        </div>
    )
}

const H2 = (props) => {
    const text = props.children
    const styles = props.styles
    
    return (
        <div className={styles.h2}>
            <h2>{text}</h2>
            <div></div>
        </div>
    )
}

export { H1, H2 }