import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
} from "react-router-dom"

import CurrentPage from './CurrentPage';


function App() {
  return (
    <>
      <Router>
        <CurrentPage></CurrentPage>
      </Router>
    </>
  )
}

export default App;
