import styles from './DesktopSidebar.module.css'

import NavButtonDropDown from './smallerPieces/NavButtonDropDown'
import { Link } from 'react-router-dom'

const DesktopSidebar = (props) => {
    const currentPage = props.currentPage
    return(
        <div className={styles.sidebar}>
            <div className={styles.logo}>
                <div className={styles.logo__blackBar}></div>
                <div className={styles.logo__yellowBar}></div>
                <div className={styles.logo__redBar}></div>
                <div className={styles.logo__svg}><img src="/res/svg/logo_white.svg"></img></div>
            </div>
            <nav className={styles.nav} >
                <Link to={'/'}>
                    <div className={`${styles.navButton} ${currentPage === "home"? styles.currentNavButton:''}`}>Home</div>
                </Link>
                <Link to={'about-us'}>
                    <div className={`${styles.navButton} ${currentPage === "about-us"? styles.currentNavButton:''}`}>The Club</div>
                </Link>
                <Link to={'join-the-team'}>
                    <div className={`${styles.navButton} ${currentPage === "join-the-team"? styles.currentNavButton:''}`}>Join the Team</div>
                </Link>
                <Link to={'training'}>
                    <div className={`${styles.navButton} ${currentPage === "training"? styles.currentNavButton:''}`}>Trainings</div>
                </Link>
                <Link to={'courses-and-certifications'}>
                    <div className={`${styles.navButton} ${currentPage === "courses-and-certifications"? styles.currentNavButton:''}`}>Courses and Certifications</div>
                </Link>
                <NavButtonDropDown isMobile={false} text={'Events'} styles={styles} currentPage={currentPage} />
                <Link to={'results'}>
                    <div className={`${styles.navButton} ${currentPage === "results"? styles.currentNavButton:''}`}>Media</div>
                </Link>
                <Link to={'contact-us'}>
                    <div className={`${styles.navButton} ${currentPage === "contact-us"? styles.currentNavButton:''}`}>Contact us</div>
                </Link>
            </nav>
            <div className={styles.info}>
                <img></img>
                <p className={styles.info__name}>Parlee Beach Lifesaving Club</p>
                <p className={styles.info__contact}>parleebeachlifesavingclub@gmail.com  /  506-377-4227</p>
                <div className={styles.info__socials}>
                    <a href="https://www.facebook.com/ParleeBeachLifesavingClub"><img src="res/svg/facebook_logo_white.svg"></img></a>
                    <a href="https://www.instagram.com/parleebeachlifesavingclub/"><img src="res/svg/ig_logo_white.svg"></img></a>
                </div>
            </div>
        </div>
    )
}

export default DesktopSidebar
