import { useState, useEffect } from 'react';

//styles
import styles from './MainDesktop.module.css'

//components
import DesktopSidebar from '../components/DesktopSidebar';

const MainDesktop = ({currentPage}) => {

    const pageMain = (
        <main className={styles.main}>
            <section className={styles.section}>
                {currentPage.html}
            </section>
            <section className={styles.hideOnMobile}>
                <p className={styles.copyright}>
                    © 2021 by Parlee Beach Lifesaving Club Inc.
                </p>
                <p className={styles.credits}>
                    Website created by Justin McIntyre
                </p>
            </section>
        </main>
    )

    return (
        <>
            <DesktopSidebar currentPage={currentPage.name}/>
            {pageMain}
        </>
    )
}

export default MainDesktop