import styles from './MobileFooter.module.css'

const MobileFooter = (props) => {
    return (
        <footer className={styles.footer}>
            <hr></hr>
            <img src="res/svg/logo_black.svg" />
            <p className={styles.name}>Parlee Beach Lifesaving Club</p>
            <p className={styles.contact}>parleebeachlifesavingclub@gmail.com / 506-377-4227</p>
            <div className={styles.socials}>
                <a href="https://www.facebook.com/ParleeBeachLifesavingClub"><img src="res/svg/facebook_logo_black.svg"></img></a>
                <a href="https://www.instagram.com/parleebeachlifesavingclub/"><img src="res/svg/ig_logo_black.svg"></img></a>
            </div>
            <div>
                <p className={styles.copyright}>
                    © 2021 by Parlee Beach Lifesaving Club Inc.
                </p>
                <p className={styles.credits}>
                    Website created by Justin McIntyre
                </p>
            </div>
        </footer>
    )
}

export default MobileFooter