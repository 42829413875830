import { useState, useRef } from 'react'
import { Link } from 'react-router-dom'

import styles from './MobileNav.module.css'

import NavButtonDropDown from './smallerPieces/NavButtonDropDown'

const MobileNav = (props) => {

    const [ isDropdownExpanded, setIsDropdownExpanded ] = useState(false)
    
    const dropDownIconSVGLink = './res/svg/dropdown.svg'
    const XIconLink = './res/svg/X.svg'

    const currentPage = props.currentPage

    const handleDropdownOnClick = (e) => {
        if(isDropdownExpanded){
            setIsDropdownExpanded(false)
        }else{
            setIsDropdownExpanded(true)
        }
    }

    return (
        <nav className={styles.nav}>
            <div className={styles.topBar}>
                <div className={styles.redBar}></div>
                <div className={styles.yellowBar}></div>
                <div className={styles.topBar__logo}><img src="./res/svg/logo_white.svg"></img></div>
                <div className={styles.dropDownIcon} onClick={handleDropdownOnClick}>
                    { isDropdownExpanded || <img src={dropDownIconSVGLink}></img> }
                    { isDropdownExpanded && <img src={XIconLink}></img> }
                </div>
            </div>
            {isDropdownExpanded && 
                <div className={styles.dropDown}>
                    <Link onClick={()=>{setIsDropdownExpanded(false)}} to={'/'}>
                        <div className={`${styles.navButton} ${currentPage === "home"? styles.currentNavButton:''}`}>Home</div>
                    </Link>
                    <Link onClick={()=>{setIsDropdownExpanded(false)}} to={'/about-us'}>
                        <div className={`${styles.navButton} ${currentPage === "about-us"? styles.currentNavButton:''}`}>The Club</div>
                    </Link>
                    <Link onClick={()=>{setIsDropdownExpanded(false)}} to={'/join-the-team'}>
                        <div className={`${styles.navButton} ${currentPage === "join-the-team"? styles.currentNavButton:''}`}>Join the Team</div>
                    </Link>
                    <Link onClick={()=>{setIsDropdownExpanded(false)}} to={'/training'}>
                        <div className={`${styles.navButton} ${currentPage === "training"? styles.currentNavButton:''}`}>Trainings</div>
                    </Link>
                    <Link onClick={()=>{setIsDropdownExpanded(false)}} to={'/courses-and-certifications'}>
                        <div className={`${styles.navButton} ${currentPage === "courses-and-certifications"? styles.currentNavButton:''}`}>Courses and Certifications</div>
                    </Link>
                    <NavButtonDropDown isMobile={true} text={'Events'} setIsDropdownExpanded={setIsDropdownExpanded} styles={styles} currentPage={currentPage} />
                    <Link onClick={()=>{setIsDropdownExpanded(false)}} to={'/results'}>
                        <div className={`${styles.navButton} ${currentPage === "results"? styles.currentNavButton:''}`}>Media</div>
                    </Link>
                    <Link onClick={()=>{setIsDropdownExpanded(false)}} to={'/contact-us'}>
                        <div className={`${styles.navButton} ${currentPage === "contact-us"? styles.currentNavButton:''}`}>Contact us</div>
                    </Link>
                </div>
            }
        </nav>
    )
}
export default MobileNav
