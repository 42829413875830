import { useState, useEffect } from 'react'

//styles
import styles from './MainMobile.module.css'

//components
import MobileNav from '../components/MobileNav'
import MobileFooter from '../components/MobileFooter'

//import { fetchPages } from '../apis/WP_fetch'

const MainDesktop = (props) => {
    const currentPage = props.currentPage

    //WP REST CONFIG
    const homepageID = 5
    const WPRoot = "https://localhost/parleebeach"

    const [JSXCollection, setJSXCollection] = useState('')
    const [loading, setLoading] = useState(true)

    // useEffect(()=>{
    //     fetchPages(styles).then((JSXCollection)=>{
    //       setJSXCollection(JSXCollection)
    //       setLoading(false)
    //     })
    // },[])

    return (
        <>
            <MobileNav currentPage={currentPage.name}/>
            <main className={styles.main}>
                <section className={styles.section}>
                    {props.currentPage.html}
                </section>
            </main>
                
            <MobileFooter />
        </>
    )
}

export default MainDesktop