import { useState } from 'react'
import { Link } from 'react-router-dom'

const NavButtonDropDown = (props) => {
    const styles=props.styles
    const text=props.text
    const currentPage=props.currentPage

    const [ isDropdownExpanded, setIsDropdownExpanded ] = useState(false)

    const handleDropdownOnClick = (e) => {
        if(isDropdownExpanded){
            setIsDropdownExpanded(false)
        }else{
            setIsDropdownExpanded(true)
        }
    }

    if(props.isMobile){
        return (
            <>
                <div className={styles.navButton} onClick={handleDropdownOnClick}>
                    {`${text} `}
                    {isDropdownExpanded || <img src="./res/svg/arrow.svg"></img>}
                    {isDropdownExpanded && <img src="./res/svg/arrowInverted.svg"></img>}
                </div>
                {isDropdownExpanded &&
                    <>
                        <Link to={'competition'} onClick={()=>{setIsDropdownExpanded(false);props.setIsDropdownExpanded(false)}}>
                            <div className={`${styles.secondaryNavButton} ${currentPage === "competition" && styles.currentNavButton}`}>Competitions</div>
                        </Link>
                        <Link to={'education-session'} onClick={()=>{setIsDropdownExpanded(false);props.setIsDropdownExpanded(false)}}>
                            <div className={`${styles.secondaryNavButton} ${currentPage === "education-session" && styles.currentNavButton}`}>Results</div>
                        </Link>
                        <Link to={'summer-camp'} onClick={()=>{setIsDropdownExpanded(false);props.setIsDropdownExpanded(false)}}>
                            <div className={`${styles.secondaryNavButton} ${currentPage === "summer-camp" && styles.currentNavButton}`}>Summer Camp</div>
                        </Link>
                        <Link to={'agm'} onClick={()=>{setIsDropdownExpanded(false);props.setIsDropdownExpanded(false)}}>
                            <div className={`${styles.secondaryNavButton} ${currentPage === "agm" && styles.currentNavButton}`}>AGM</div>
                        </Link>
                    </>
                }
            </>
        )
    }else{
        return (
            <>
                <div className={styles.navButton} onClick={handleDropdownOnClick}>
                    {`${text} `}
                    {isDropdownExpanded || <img src="./res/svg/arrow.svg"></img>}
                    {isDropdownExpanded && <img src="./res/svg/arrowInverted.svg"></img>}
                </div>
                {isDropdownExpanded &&
                    <>
                        <Link to={'competition'}>
                            <div className={`${styles.secondaryNavButton} ${currentPage === "competition" && styles.currentNavButton}`}>Competitions</div>
                        </Link>
                        <Link to={'education-session'}>
                            <div className={`${styles.secondaryNavButton} ${currentPage === "education-session" && styles.currentNavButton}`}>Results</div>
                        </Link>
                        <Link to={'summer-camp'}>
                            <div className={`${styles.secondaryNavButton} ${currentPage === "summer-camp" && styles.currentNavButton}`}>Summer Camp</div>
                        </Link>
                        <Link to={'agm'}>
                            <div className={`${styles.secondaryNavButton} ${currentPage === "agm" && styles.currentNavButton}`}>AGM</div>
                        </Link>
                    </>
                }
            </>
        )
    }

}

export default NavButtonDropDown