import { H1, H2 } from "../components/smallerPieces/styleComponents"

async function fetchPageBySlug(slug, styles) {
  let url = 'https://localhost/parleebeach'
  url = 'https://parlee.justinmcintyre.com'
  return fetch(`${url}/wp-json/wp/v2/pages?slug=${slug}`)
    .then((response) => {
      return response.json()
    })
    .then((JSON)=>{
      let doc = new DOMParser().parseFromString("<body>"+JSON[0].content.rendered+"</body>", "text/html")
      let wp_content = doc.getElementsByTagName("body")[0]
      let children = Array.from(wp_content.children)
      let JSX = []
      children.forEach((child, index)=>{
        let NodeName = child.nodeName
        switch(NodeName){
          case "H1":
            JSX.push(<H1 styles={styles} key={index}>{child.innerHTML}</H1>)
            break;
          case "H2":
            JSX.push(<H2 styles={styles} key={index}>{child.innerHTML}</H2>)
            break;
          default:
            NodeName = NodeName.toLowerCase()
            let className = ''
            if(styles[NodeName]){
              className = styles[NodeName]
            }
            JSX.push(<NodeName className={className} key={index} dangerouslySetInnerHTML={{ __html: child.innerHTML}} />)
        }
      })
      return JSX
    })

}

export {fetchPageBySlug}